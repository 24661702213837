var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-text-field', {
    attrs: {
      "label": "Content",
      "hint": "You may use [last_name] [first_name] [expiry_date] and any other string",
      "rules": [_vm.required],
      "outlined": ""
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Font Size (must be installed)",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.fontOptions
    },
    model: {
      value: _vm.form.font,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "font", $$v);
      },
      expression: "form.font"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Font Size",
      "outlined": "",
      "rules": [_vm.between(_vm.form.size, 3, 9999)]
    },
    model: {
      value: _vm.form.size,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "size", $$v);
      },
      expression: "form.size"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "X coordinate",
      "outlined": "",
      "rules": [_vm.between(_vm.form.size, 0, 9999)]
    },
    model: {
      value: _vm.form.x,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "x", $$v);
      },
      expression: "form.x"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Y coordinate",
      "outlined": "",
      "rules": [_vm.between(_vm.form.size, 0, 9999)]
    },
    model: {
      value: _vm.form.y,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "y", $$v);
      },
      expression: "form.y"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Color",
      "hint": "#000000, #81583e",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.color,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "color", $$v);
      },
      expression: "form.color"
    }
  }), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }