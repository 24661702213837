import axios from '@axios'

const storeCardLayout = data => axios.post('/admin/card-layouts', data)

const boilerplateCardLayout = id => axios.post(`/admin/card-layouts/${id}/boilerplate`)

const fetchCardLayouts = params => axios.get('/admin/card-layouts', { params })

const fetchLayoutPreviewPng = id => axios.get(`/admin/card-layouts/${id}/preview.png`, { responseType: 'blob' })

const destroyCardLayout = id => axios.delete(`/admin/card-layouts/${id}`)

const updateLayoutElement = (id, data) => axios.put(`/admin/card-layout-elements/${id}`, data)

const createLayoutElement = data => axios.post('/admin/card-layout-elements', data)

const destroyLayoutElement = id => axios.delete(`/admin/card-layout-elements/${id}`)

export {
  storeCardLayout,
  boilerplateCardLayout,
  fetchCardLayouts,
  fetchLayoutPreviewPng,
  destroyCardLayout,
  updateLayoutElement,
  createLayoutElement,
  destroyLayoutElement,
}
