import { useConfirm, useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { destroyCardLayout, fetchCardLayouts, fetchLayoutPreviewPng } from '@api/medical/cardLayout'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useList() {
  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'Layout', value: 'layout', sortable: false },
    { text: 'PASS', value: 'plan_pass_id' },
    { text: 'Content', value: 'content', sortable: false },
    { text: 'Size', value: 'size', sortable: false },
    { text: 'Font', value: 'font', sortable: false },
    { text: 'Color', value: 'color', sortable: false },
    { text: 'X', value: 'x', sortable: false },
    { text: 'Y', value: 'y', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const tableTotal = ref(0)
  const loading = ref(false)
  const tableSelectedData = ref([])

  const loadLayouts = () =>
    fetchCardLayouts(
      useTableOptions(options.value, {
        search: searchQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        tableList.value = records
        tableTotal.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const deleteLayout = async layout => {
    await useConfirm({
      title: 'Confirm Delete',
      content: 'Are You Sure? Note: Used layout cannot be deleted.',
    })
    destroyCardLayout(layout.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadLayouts()
      })
      .catch(useNotifyErrors)
  }

  const previewLayout = async layout => {
    fetchLayoutPreviewPng(layout.id).then(response => {
      const href = URL.createObjectURL(response.data)

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'preview.png') // or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []
    loadLayouts()
    cache()
  })

  return {
    tableColumns,
    searchQuery,
    options,
    tableList,
    tableTotal,
    loading,
    tableSelectedData,
    loadLayouts,
    deleteLayout,
    previewLayout,
  }
}
