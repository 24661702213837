<template>
  <v-form ref="formElem">
    <v-card>
      <v-text-field
        v-model="form.content"
        label="Content"
        hint="You may use [last_name] [first_name] [expiry_date] and any other string"
        :rules="[required]"
        outlined
      />

      <v-select
        v-model="form.font"
        label="Font Size (must be installed)"
        item-text="title"
        item-value="value"
        outlined
        :items="fontOptions"
      ></v-select>

      <v-text-field
        v-model="form.size"
        label="Font Size"
        outlined
        :rules="[between(form.size, 3, 9999)]"
      />

      <v-text-field
        v-model="form.x"
        label="X coordinate"
        outlined
        :rules="[between(form.size, 0, 9999)]"
      />
      <v-text-field
        v-model="form.y"
        label="Y coordinate"
        outlined
        :rules="[between(form.size, 0, 9999)]"
      />

      <v-text-field
        v-model="form.color"
        label="Color"
        hint="#000000, #81583e"
        outlined
        :rules="[required]"
      />

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { between, required } from '@core/utils/validation'
import {} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('content', ''),
      ...field('font', 'noto_sans_black'),
      ...field('color', '#ffffff'),
      ...field('size', 54),
      ...field('x', 0),
      ...field('y', 0),
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const fontOptions = [
      { title: 'noto_sans_black', value: 'noto_sans_black' },
      { title: 'source_sans_pro_regular', value: 'source_sans_pro_regular' },
      { title: 'source_sans_pro_black', value: 'source_sans_pro_black' },
    ]

    return {
      form,
      formElem,

      validate,
      required,
      between,

      fontOptions,
    }
  },
}
</script>
