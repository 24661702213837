<template>
  <v-navigation-drawer
    :value="active"
    permanent
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    app
    @input="val => $emit('aside', val)"
  >
    <div style="padding: 1rem;">
      <a @click="$emit('aside', false)">
        Back
      </a>

      <layout-element-form
        class="mt-4"
        :resource="resource"
        :loading="loading"
        :errors="errors"
        @submit="handleSubmit"
      />

      <div class="mt-4 text-center">
        <a
          v-if="resource"
          @click="deleteElement"
        >Delete this element</a>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useConfirm, useExtractErrors } from '@/composables'
import { createLayoutElement, destroyLayoutElement, updateLayoutElement } from '@api/medical/cardLayout'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import LayoutElementForm from './LayoutElementForm.vue'

export default {
  components: { LayoutElementForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    layout: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      let request
      const mode = props.resource ? 'updated' : 'created'
      const data = { ...form }
      if (mode === 'created') {
        data.layout_id = props.layout.id
        request = createLayoutElement(data)
      } else {
        request = updateLayoutElement(props.resource.id, data)
      }

      await request
        .then(res => {
          emit(mode, res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const deleteElement = async () => {
      await useConfirm({ title: 'Confirm Delete', content: 'You can create again.' })
      destroyLayoutElement(props.resource.id)
      emit('changed')
    }

    return {
      handleSubmit,
      loading,
      errors,

      deleteElement,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
