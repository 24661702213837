<template>
  <div>
    <v-card>
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-5">
        <v-spacer />
        <v-btn
          color="success"
          @click="setAside(null, 'layout')"
        >
          Create +
        </v-btn>
      </v-card-text> -->

      <!-- table -->
      <v-data-table
        v-model="tableSelectedData"
        :headers="tableColumns"
        :items="tableList"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <template #[`item.layout`]="{item}">
          <img
            :src="item.instance.thumbnail"
            width="120"
          >
        </template>

        <template #[`item.plan_pass_id`]="{item}">
          <div>
            {{ t(item.plan_pass.title) }}
            <span v-if="item.plan_pass.distributor">({{ item.plan_pass.distributor.slug }})</span>
          </div>
        </template>

        <template #[`item.content`]="{item}">
          <div
            v-for="element in item.elements"
            :key="element.id"
          >
            {{ element.content }}
            <v-icon @click="layout = item; setAside(element, 'layout_element')">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </template>

        <template #[`item.font`]="{item}">
          <div
            v-for="element in item.elements"
            :key="element.id"
          >
            {{ element.font }}
          </div>
        </template>

        <template #[`item.color`]="{item}">
          <div
            v-for="element in item.elements"
            :key="element.id"
          >
            {{ element.color }}
          </div>
        </template>

        <template #[`item.size`]="{item}">
          <div
            v-for="element in item.elements"
            :key="element.id"
          >
            {{ element.size }}
          </div>
        </template>

        <template #[`item.x`]="{item}">
          <div
            v-for="element in item.elements"
            :key="element.id"
          >
            {{ element.x }}
          </div>
        </template>

        <template #[`item.y`]="{item}">
          <div
            v-for="element in item.elements"
            :key="element.id"
          >
            {{ element.y }}
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="previewLayout(item)"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2">
                      {{ icons.mdiEye }}
                    </v-icon>
                    <span>Preview Image</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="layout = item ; setAside(null, 'layout_element')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2">
                      {{ icons.mdiCardPlus }}
                    </v-icon>
                    <span>Add element</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="deleteLayout(item)"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                  link
                  @click="setAside(item, 'layout_element')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                    </v-icon>
                    <span>Update Elements</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list> -->
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- <layout-aside
      v-if="aside === 'layout'"
      v-model="asideActive"
      :resource="resource"
      @aside="val => { if (!val) { aside = '' } }"
      @changed="changedLayout"
    ></layout-aside> -->

    <layout-element-aside
      v-if="aside === 'layout_element'"
      v-model="asideActive"
      :layout="layout"
      :resource="resource"
      @aside="val => { if (!val) { aside = '' } }"
      @changed="closeElementAside"
    ></layout-element-aside>
  </div>
</template>

<script>
import {
mdiCardPlus, mdiDeleteOutline, mdiDotsVertical, mdiEye, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'

import LayoutElementAside from '@/modules/medical/views/card-layout-resource/LayoutElementAside.vue'

// import LayoutAside from '../layout-resource/LayoutAside.vue'
import useLayoutList from './useLayoutList'

export default {
  components: {
    LayoutElementAside,
  },

  setup() {
    const {
      tableList, tableColumns, tableTotal, tableSelectedData, searchQuery, options, loadLayouts, previewLayout, deleteLayout, loading,
    } = useLayoutList()

    const asideActive = ref(false)
    const layout = ref(null)
    const resource = ref(null)
    const aside = ref(null)
    const setAside = (item, type) => {
      asideActive.value = true
      aside.value = type
      resource.value = item
    }

    const getLayout = item => item.layouts[item.layouts.length - 1]

    const changedLayout = newLayout => {
      loadLayouts()
      if (!resource.value || !resource.value.layouts.length) {
        resource.value = newLayout
        aside.value = 'layout_element'
      } else {
        aside.value = ''
        asideActive.value = false
      }
    }

    const closeElementAside = () => {
      loadLayouts()
      resource.value = null
      asideActive.value = false
      aside.value = null
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      searchQuery,

      loadLayouts,
      previewLayout,
      deleteLayout,

      setAside,
      asideActive,
      aside,
      layout,
      resource,

      options,
      loading,

      t,
      getLayout,
      changedLayout,
      closeElementAside,

      icons: {
        mdiEye,
        mdiCardPlus,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
