var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableList,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.layout",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('img', {
          attrs: {
            "src": item.instance.thumbnail,
            "width": "120"
          }
        })];
      }
    }, {
      key: "item.plan_pass_id",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.t(item.plan_pass.title)) + " "), item.plan_pass.distributor ? _c('span', [_vm._v("(" + _vm._s(item.plan_pass.distributor.slug) + ")")]) : _vm._e()])];
      }
    }, {
      key: "item.content",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return _vm._l(item.elements, function (element) {
          return _c('div', {
            key: element.id
          }, [_vm._v(" " + _vm._s(element.content) + " "), _c('v-icon', {
            on: {
              "click": function click($event) {
                _vm.layout = item;
                _vm.setAside(element, 'layout_element');
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")])], 1);
        });
      }
    }, {
      key: "item.font",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return _vm._l(item.elements, function (element) {
          return _c('div', {
            key: element.id
          }, [_vm._v(" " + _vm._s(element.font) + " ")]);
        });
      }
    }, {
      key: "item.color",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return _vm._l(item.elements, function (element) {
          return _c('div', {
            key: element.id
          }, [_vm._v(" " + _vm._s(element.color) + " ")]);
        });
      }
    }, {
      key: "item.size",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return _vm._l(item.elements, function (element) {
          return _c('div', {
            key: element.id
          }, [_vm._v(" " + _vm._s(element.size) + " ")]);
        });
      }
    }, {
      key: "item.x",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return _vm._l(item.elements, function (element) {
          return _c('div', {
            key: element.id
          }, [_vm._v(" " + _vm._s(element.x) + " ")]);
        });
      }
    }, {
      key: "item.y",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return _vm._l(item.elements, function (element) {
          return _c('div', {
            key: element.id
          }, [_vm._v(" " + _vm._s(element.y) + " ")]);
        });
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref11) {
              var on = _ref11.on,
                attrs = _ref11.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.previewLayout(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiEye) + " ")]), _c('span', [_vm._v("Preview Image")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              _vm.layout = item;
              _vm.setAside(null, 'layout_element');
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiCardPlus) + " ")]), _c('span', [_vm._v("Add element")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteLayout(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true),
    model: {
      value: _vm.tableSelectedData,
      callback: function callback($$v) {
        _vm.tableSelectedData = $$v;
      },
      expression: "tableSelectedData"
    }
  })], 1), _vm.aside === 'layout_element' ? _c('layout-element-aside', {
    attrs: {
      "layout": _vm.layout,
      "resource": _vm.resource
    },
    on: {
      "aside": function aside(val) {
        if (!val) {
          _vm.aside = '';
        }
      },
      "changed": _vm.closeElementAside
    },
    model: {
      value: _vm.asideActive,
      callback: function callback($$v) {
        _vm.asideActive = $$v;
      },
      expression: "asideActive"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }