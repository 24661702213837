var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "value": _vm.active,
      "permanent": "",
      "temporary": "",
      "touchless": "",
      "right": !_vm.$vuetify.rtl,
      "width": _vm.$vuetify.breakpoint.smAndUp ? 380 : '100%',
      "app": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('aside', val);
      }
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "1rem"
    }
  }, [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.$emit('aside', false);
      }
    }
  }, [_vm._v(" Back ")]), _c('layout-element-form', {
    staticClass: "mt-4",
    attrs: {
      "resource": _vm.resource,
      "loading": _vm.loading,
      "errors": _vm.errors
    },
    on: {
      "submit": _vm.handleSubmit
    }
  }), _c('div', {
    staticClass: "mt-4 text-center"
  }, [_vm.resource ? _c('a', {
    on: {
      "click": _vm.deleteElement
    }
  }, [_vm._v("Delete this element")]) : _vm._e()])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }